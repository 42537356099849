<template>
    <!-- 考勤详情 -->
    <div class="coursePersonnel-list">
        <div class="manage-wrapper">
            <div class="table-wrapper">
                <h2 class="table-title">
                    <img src="../../assets/images/main/bulltin-icon.png" alt="" class="title-icon">考勤详情</h2>
                <div class="export-btn csp" style="margin-right:10px" @click="attendanceExportPath"><i
                        class="export-icon"></i>导出
                </div>
                <div class="table">
                    <el-table :data="tableData" border style="width: 100%">
                        <el-table-column type="index" label="序号" style="background:#2373EC" align="center">
                        </el-table-column>
                        <el-table-column prop="userName" label="教师姓名" align="center"></el-table-column>
                        <el-table-column prop="phone" label="手机号" align="center"></el-table-column>
                        <el-table-column prop="area" label="地区" align="center" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column prop="schoolName" label="学校" align="center" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column prop="time" label="打卡时间" align="center">
                        </el-table-column>
                        <el-table-column prop="fileUrl" label="打卡地点" align="center">
                            <template slot-scope="scope">
                                <el-button type="text" size="small" :disabled="!scope.row.fileUrl"
                                    @click="previewImage(scope.row.fileUrl)">
                                    {{scope.row.fileName || '无'}}</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <pagination v-show="pages.total > 0" :total="pages.total" :page.sync="pages.currentPage"
                        :limit.sync="pages.pageSize" @pagination="handlePageChange">
                    </pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                id: 89,
                pages: { //分页
                    currentPage: 1,
                    pageSize: 10,
                    total: 0,
                },
                tableData: [],
                imageList: [],
                loadingInstance: '',
                interval: null,
            }
        },

        methods: {
            handlePageChange(data) {
                console.log('data:',data)
                this.pages.currentPage = data.pageIndex
                this.pages.pageSize = data.pageSize
                this.getAttendanceListDate();
            },
            previewImage(url) {
                this.imageList = [];
                this.imageList.push(this.downloadURL + url);
                this.$previewImgs(this.imageList)
            },
            async getAttendanceListDate() {
                let params = {
                    page: this.pages.currentPage,
                    limit: this.pages.pageSize,
                    projectId: this.$store.state.user.currentProject.id,
                    date: JSON.parse(this.$route.query.date),
                    type: this.$route.query.type
                }
                let resData = await this.$Api.Project.getAttendanceListDate(params);
                console.log(resData);
                this.tableData = resData.data.records
                this.pages.total = resData.data.total
            },
            // 按人导出 获取打包地址
            async attendanceExportPath() {
                let pramas = {
                    projectId: this.$store.state.user.currentProject.id,
                    type: this.$route.query.type,
                    page: this.pages.currentPage,
                    limit: this.pages.pageSize,
                    // date: this.dayjs(JSON.parse(this.$route.query.date)).format("YYYY-MM-DD")
                    date: JSON.parse(this.$route.query.date)
                }
                let resData = await this.$Api.Project.attendanceExportPath(pramas)
                console.log(resData);
                if (resData.code == 200) {
                    this.loadingInstance = this.$loading({
                        lock: true,
                        text: '数据打包中...  请您耐心等待。',
                        spinner: 'el-icon-loading',
                    });
                    this.interval = setInterval(() => {
                        this.attendanceExportState(pramas)
                    }, 3000)
                } else {
                    console.log(resData.msg)
                    this.$message.error('打包失败，请联系管理员')
                }
            },
            // 查询打包状态
            async attendanceExportState(pramas) {
                let resData = await this.$Api.Project.attendanceExportState(pramas)
                if (resData.code == 200 && resData.data.state) {
                    // console.log('打包成功');
                    clearInterval(this.interval);
                    if (this.loadingInstance) {
                        this.loadingInstance.close()
                    }
                    this.getDownloadUrl(resData.data.identity);
                } else {
                    clearInterval(this.interval);
                    this.loadingInstance.close()
                    this.$message.error('打包失败，请联系管理员')
                }
            },
            // 获取打包好的文件地址
            async getDownloadUrl(identity) {
                let pramas = {
                    identifier: identity,
                }
                let resData = await this.$Api.Home.getDownloadUrl(pramas);
                // console.log('地址', resData);
                if (resData.data.url) {
                    window.location.href = resData.data.url;
                }
            },
        },
        mounted() {
            this.getAttendanceListDate();
            console.log(this.$route.query.date);
        }
    }
</script>

<style lang="less" scoped>
    @import "../manage/less/table.less";

    .coursePersonnel-list {

        .table-wrapper {
            border-radius: 8px;
            padding-top: 10px;
            background: #fff;
            padding: 0 26px 24px;
            box-shadow: 0px 2px 10px 0px #F5F8FA;
        }

        .table-title {
            line-height: 68px;
            font-size: 17px;
        }

        .export-btn {
            color: #595959;
            display: flex;
            justify-content: flex-end;
            margin-bottom: 10px;
        }

        .export-icon {
            display: inline-block;
            background-image: url("~@/assets/images/admin/export.png");
            background-size: 100% 100%;
            width: 17px;
            height: 16px;
            margin-right: 8px;
            vertical-align: -2px;
        }

        .title-icon {
            width: 21px;
            height: 21px;
            margin-right: 9px;
            vertical-align: -4px;
        }

        .table {
            /deep/.el-table th {
                &>.cell {
                    white-space: pre-line;
                }
            }
        }

        @media screen and (min-width:1250px) {
            .table-wrapper {
                padding: 0 43px 24px;
            }
        }
    }
</style>